const config = {
    "project-service": localStorage.getItem('project-service') ? localStorage.getItem('project-service') : "project-service",
    "file-manage-service": localStorage.getItem('file-manage-service') ? localStorage.getItem('file-manage-service') : "file-manage-service",
    "account-service": localStorage.getItem('account-service') ? localStorage.getItem('account-service') : "account-service",
    "mindmap-service": localStorage.getItem('mindmap-service') ? localStorage.getItem('mindmap-service') : "mindmap-service",
    "mindmap-service-v2": localStorage.getItem('mindmap-service-v2') ? localStorage.getItem('mindmap-service-v2') : "mindmap-service-v2",
    "application-store-service": localStorage.getItem('application-store-service') ? localStorage.getItem('application-store-service') : "application-store-service",
    "alm": localStorage.getItem('alm') ? localStorage.getItem('alm') : "alm",
}


export default config