import Vue from "vue";
import VueRouter from "vue-router";
import {
  getToken,
  setToken
} from "@/utils/auth";
import i18n from "@/i18n/index";
import {
  get_project_members
} from "@/network/projectMember/index.js";
Vue.use(VueRouter);
// 解决重复点击导航菜单报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const login = () => import("@/views/login/Login_new.vue");
const acceptInvitation = () => import("@/views/acceptInvitation/index.vue");
const login_email = () => import("@/views/login/Logine.vue");
const login_password = () => import("@/views/login/Loginp.vue");
const register = () => import("@/views/login/Loginr.vue");

// 绑定
const thirdparty = () => import("@/views/login/thirdParty.vue");
const forget_psd = () => import("@/views/login/ResetPassword.vue");

// 手机密码登录
const phoneloginp = () => import('@/views/phoneLogin/phoneloginp.vue');
// 手机验证码登录
const phonelogine = () => import('@/views/phoneLogin/phonelogine.vue');
// 手机注册
const phoneloginr = () => import('@/views/phoneLogin/phoneloginr.vue');
// 手机忘记密码
const phoneforget_psd = () => import('@/views/phoneLogin/phoneforget_psd');
// 手机第三方绑定
const phonethirdparty = () => import('@/views/phoneLogin/phonethirdparty');
// 手机接收邀请
const phone_acceptInvitation = () => import('@/views/phoneLogin/phone_acceptInvitation');

const work_space = () => import("@/views/workspace/index.vue");
// 主页
const home = () => import("@/views/Home.vue");
// 选择大模型
const choseLargelanguage = () => import("@/views/choseLargelanguage/index.vue");
const guide = () => import("@/views/guide/index.vue");
const my_file = () => import("@/views/homemyFile/index.vue");
const node = () => import("@/views/nodeList/index.vue");
const my_collaboration = () => import("@/views/homeMyCollaboration/index.vue");
const home_change_request = () => import("@/views/changeRequest/components/HomeChangeRequest.vue");
const configurationManagement = () => import("@/views/configurationManagement/index.vue");
const notification = () => import("@/views/notification/index.vue");

const baseline = () => import("@/views/baseline/index.vue");
const version = () => import("@/views/version/index.vue");
const test = () => import("@/views/testplan/index.vue");
const gantt = () => import("@/views/gantt/index.vue")

// 工作台
const new_work_space = () => import("@/views/workspace/indexNew.vue");
const dataSummarizing = () => import("@/views/workspace/dataSummarizing/index.vue")

// 个人主页
const personal = () => import("@/views/personal/index.vue");
const my_info = () => import("@/views/myInfo/index.vue");
const user_manage = () => import("@/views/userManagement/index.vue");
const user_group_manage = () => import("@/views/projectgroupManagement/index.vue")
const project_manage = () =>
  import("@/views/projectManagement/index.vue");
const open_api = () => import("@/views/openApi/index.vue");
const third_party = () => import("@/views/thirdParty/index.vue");
const Large_language = () => import("@/views/largelanguage/index.vue");
const other_settings = () => import("@/views/otherSettings/index.vue");
const third_party_detail = () =>
  import("@/views/thirdpartyDetail/index.vue");
const third_party_setting = () =>
  import("@/views/thirdpartySetting/index.vue");
const reset_email = () => import("@/views/resetEmail/index.vue");
const reset_password = () => import("@/views/login/ResetPassword.vue");
const myMind = () => import("@/views/myMind/index.vue");
// 项目详情
const project_details = () => import("@/views/projectDetails/index.vue");
// 变更请求
const change_request = () => import("@/views/changeRequest/index.vue");
const permission = () => import("@/views/homePermission/index.vue");
const user_setting = () => import("@/views/projectmemberSetting/index.vue");
const work_flow = () => import("@/views/workFlow/index.vue");
const template_set = () => import("@/views/templateSet/index.vue");
const field_lab = () => import("@/views/fieldLab/index.vue");
const bin = () => import("@/views/homeBin/index.vue");
const lineword = () => import("@/views/mindWord/index.vue");
// 思维导图相关页面
const node_detail = () => import("@/views/mindmap/index.vue");
// 敏捷看板
const quick = () => import("@/views/quick/index.vue");
//敏捷看板设置页
const quick_setting = () => import("@/views/quick/setting.vue");
// 敏捷看板编辑页
const quick_edit = () => import("@/views/quick/edit.vue");
// 数据报表
const chart = () => import("@/views/dataBoard/index.vue");
const zdh_up_file = () => import("@/views/uploadFile/index.vue");

// 验证地址
const authentication = () => import("@/views/authentication/index.vue");
const authenticationGitee = () => import("@/views/authenticationGitee/index.vue");
const authenticationGithub = () => import("@/views/authenticationGithub/index.vue");
const authenticationJira = () => import("@/views/authenticationJira/index.vue");
const authenticationOnes = () => import("@/views/authenticationOnes/index.vue");

// 钉钉接收页
const loginDingTalk = () => import("@/views/loginDingTalk/index.vue");
// 微信接受页
const loginWeChat = () => import("@/views/loginWeChat/index.vue");

//测试完成（调试用，接口联调后删除该路由）
const testComplete = () => import("@/views/testComplete/index.vue");

//评审
const pingshen = () => import("@/views/pingShen/index.vue");
// 删除项目评审页面
const deleteProject = () => import("@/views/deleteProject/index.vue");
// 知识库页面
const knowledge = () => import("@/views/knowledge/index.vue");
const documentSpace = () => import("@/views/documentSpace/index.vue");

const knowledgeBin = () => import("@/views/knowledgeBin/index.vue");
// 公共知识库页面
const pubKnowledge = () => import("@/views/pubKnowledge/index.vue");
// 外部文章取消订阅页面
const unSubscribe = () => import("@/views/pubKnowledge/components/notEdit/unSubscribe.vue")
// 公共思维导图
const pubMindmap = () => import("@/views/pubMindmap/index.vue");
// 公共思维导图
const pubWordview = () => import("@/views/pubWordview/index.vue");
const echarts = () => import("@/views/pubMindmap/echarts.vue");
// 公共思维导图
const mm_pubMindmap = () => import("@/views/pubMindmap/mmindex.vue");
const m_pubMindmap = () => import("@/views/pubMindmap/mindex.vue");
// 公共思维导图
const m_pubWordview = () => import("@/views/pubWordview/mindex.vue");
// 知识库页面历史
const knowledgeHistory = () => import("@/views/knowledgeHistory/index.vue");
// dfmea页面
const dfmea = () => import("@/views/dfmea/index.vue");
const tara = () => import("@/views/tara/leftMeau.vue");
const hara = () => import("@/views/hara/leftMeau.vue");
// 上传oss小工具
const uploadoss = () => import("@/components/uploadoss/index.vue");
// 用于展示qr图片的地方
const qrPoster = () => import("@/views/qrPoster/index.vue");
const routes = [
  {
    path: '/tiptap',
    component: () => import('@/views/tiptap/index.vue')
  },
  {
    path: '/tiptapco',
    component: () => import('@/views/tiptap/index_co.vue')
  },
  {
    path: '/quill2html',
    component: () => import('@/views/tiptap/quill2html.vue')
  },
  {
    path: "",
    redirect: "/login",
  },
  {
    path: "/qrPoster",
    name: "qrPoster",
    component: qrPoster,
  },
  {
    path: "/zdh_up_file",
    name: "zdh_up_file",
    component: zdh_up_file,
  },
  {
    path: "/login",
    component: login,
    children: [{
      path: "acceptInvitation/:id",
      name: 'acceptInvitation',
      component: acceptInvitation
    },
    {
      path: "",
      redirect: "login_password",
    },
    {
      path: "login_password",
      name: "login_password",
      component: login_password,
      alias: i18n.t('addLogin.loginEmail.psd')
    },
    {
      path: "login_email",
      name: "login_email",
      component: login_email,
      alias: i18n.t('addLogin.loginEmail.email2')
    },
    {
      path: "register",
      component: register,
      name: "register",
      alias: i18n.t('addLogin.register.title')
    },
    {
      path: "thirdparty",
      name: "thirdparty",
      component: thirdparty,
      // alias: i18n.t('addLogin.register.title')
    },
    // {
    //   path: "register",
    //   component: register,
    // },
    {
      path: "forget_psd",
      component: forget_psd,
      name: "forget_psd",
      alias: i18n.t('addLogin.loginPsd.forget')
    },
    ],
  },
  // 手机登录
  {
    path: "/phoneloginp",
    component: phoneloginp,
    name: "phoneloginp",

  },
  {
    path: "/echarts",
    component: echarts,
    name: "echarts",

  },
  {
    path: "/model",
    component: () => import("@/views/modelView/index.vue"),
    name: "model1",

  },
  {
    path: "/phonelogine",
    component: phonelogine,
    name: "phonelogine",

  },
  {
    path: "/phoneloginr",
    component: phoneloginr,
    name: "phoneloginr",

  },
  {
    path: "/phoneforget_psd",
    component: phoneforget_psd,
    name: "phoneforget_psd",

  },
  {
    path: "/phonethirdparty",
    component: phonethirdparty,
    name: "phonethirdparty",

  },
  {
    path: "/phone_acceptInvitation/:id",
    name: 'phone_acceptInvitation',
    component: phone_acceptInvitation,

  },
  {
    path: "/authenticationGitee",
    component: authenticationGitee,
  },
  {
    path: "/authenticationGithub",
    component: authenticationGithub,
  },
  {
    path: "/authenticationJira",
    component: authenticationJira,
  },
  {
    path: "/loginDingTalk",
    component: loginDingTalk,
  },
  {
    path: "/loginWeChat",
    component: loginWeChat,
  },
  {
    path: "/pubKnowledge/:pathId",
    name: "pubKnowledge",
    component: pubKnowledge,
  },
  {
    path: "/unsubscribe",
    name: "unSubscribe",
    component: unSubscribe,
  },
  {
    path: "/pubMindmap/:pathId",
    name: "pubMindmap",
    component: pubMindmap,
  },
  {
    path: "/pubWordview/:pathId",
    name: "pubWordview",
    component: pubWordview,
  },
  {
    path: "/m/pubMindmap/:pathId",
    name: "mm_pubMindmap",
    component: mm_pubMindmap,
  },
  {
    path: "/phone/pubMindmap/:pathId",
    name: "m_pubMindmap",
    component: m_pubMindmap,
  },
  {
    path: "/m/pubWordview/:pathId",
    name: "m_pubWordview",
    component: m_pubWordview,
  },
  {
    path: "/:projectId/knowledge",
    name: 'knowledge',
    component: knowledge,
  },
  {
    path: "/:projectId/documentSpace",
    name: 'documentSpace',
    component: documentSpace,
  },
  {
    path: "/:projectId/knowledgeBin",
    component: knowledgeBin,
  },
  {
    path: "/:projectId?/knowledgeHistory",
    name: 'knowledgeHistory',
    component: knowledgeHistory,
  },
  {
    path: "/choseLargelanguage",
    component: choseLargelanguage,
    alias: i18n.t('menu.choseLargelanguage')
  },
  {
    path: "/:projectId/home",
    component: home,
    children: [{
      path: "guide",
      component: guide,
    }, {
      path: "my_file",
      component: my_file,
      alias: i18n.t('menu.file')
    },
    {
      path: "node",
      component: node,
      alias: i18n.t('menu.node')
    },
    {
      path: "my_collaboration",
      component: my_collaboration,
      alias: i18n.t('addMyColla.review')
    },
    {
      path: "my_change_request",
      component: home_change_request,
      alias: i18n.t('menu.changeRequest')
    },
    {
      path: "configurationManagement/:cur_id",
      component: configurationManagement,
      alias: i18n.t('menu.configurationManagement'),
      name: 'configurationManagement',
    },
    {
      path: "functionalSafety/dfmea",
      component: dfmea,
      name: 'DFMEA',
      alias: i18n.t('menu.DFMEA')
    },
    {
      path: "functionalSafety/tara",
      component: tara,
      name: 'TARA',
      alias: 'TARA'
    },
    {
      path: "functionalSafety/hara",
      component: hara,
      name: 'HARA',
      alias: 'HARA'
    },
    {
      path: "release/baseline/:cur_id",
      component: baseline,
      name: "baseline",
      alias: i18n.t('menu.baseline')
    },
    {
      path: "release/version/:cur_id",
      component: version,
      name: "version",
      alias: i18n.t('menu.version')
    },
    {
      path: "release/test/:cur_id",
      component: test,
      name: "test",
      alias: i18n.t('menu.test')
    },
    {
      path: "gantt/:cur_gant_id",
      component: gantt,
      name: "gantt",
      alias: i18n.t('gantt.info1')
    },
    //调试路由
    {
      path: "release/test/complete",
      component: testComplete,
    },
    {
      name: "board",
      path: "board/:sprintId",
      component: quick,
      alias: i18n.t('menu.board')
    },
    {
      name: "board_setting",
      path: "board_setting",
      component: quick_setting,
    },
    {
      path: "board_edit",
      name: "board_edit",
      component: quick_edit,
    },
    {
      path: "gitlab/authentication",
      name: "authentication",
      component: authentication,
    },
    {
      path: "bin",
      component: bin,
      alias: i18n.t('menu.bin')
    },
    {
      path: "project_details",
      name: "project_details",
      component: project_details,
      alias: i18n.t('menu.projectDetails')
    },
    {
      path: "setting/user",
      component: user_setting,
      alias: i18n.t('menu.user')
    },
    {
      path: "setting/file_type/work_flow",
      component: work_flow,
      alias: i18n.t('menu.fileType')
    },
    {
      path: "setting/file_type/template_set",
      component: template_set,
      alias: i18n.t('menu.fileType') + i18n.t('btn.editBtn'),
      meta: {
        save_test: true
      }
    },
    {
      path: "setting/file_type/field_lab",
      component: field_lab,
      alias: i18n.t('menu.fieldLab')
    },
    {
      path: "setting/file_type/pingshen",
      component: pingshen,
      alias: i18n.t('menu.reviewSettings')
    },
    {
      path: "setting/file_type/review",
      component: field_lab,
    },
    {
      path: "permission",
      component: permission,
      alias: i18n.t('menu.permissionSettings')
    },
    {
      path: "change_request",
      name: "change_request",
      component: change_request,
      alias: i18n.t('menu.changeRequest')
    },
    {
      name: "chart",
      path: "chart/:cur_dashboard",
      component: chart,
      alias: i18n.t('menu.chart')
    },
    {
      name: "uploadoss",
      path: "uploadoss",
      component: uploadoss
    }
    ],
  },
  {
    name: "lineword",
    path: "/:projectId/lineword/:file_key",
    component: lineword
  },
  {
    name: "model",
    path: "/:projectId/model/:file_key",
    component: () => import("@/views/modelView/i1.vue"),

  },
  {
    name: "tree",
    path: "/:projectId/tree/:file_key",
    component: () => import("@/views/treeView/index.vue"),

  },
  {
    path: "/:projectId/nodes/key/:nodeKey",
    component: node_detail,
    name: "node_detail",
    props: true,
  },
  {
    name: "myMind",
    path: "/:projectId/myMind/:file_key",
    component: myMind,
  },
  {
    path: "/home/personalDataSummary",
    component: work_space,
    alias: i18n.t('menu.workbench'),
  },
  // 新工作台
  {
    path: "/home",
    component: new_work_space,
    alias: i18n.t('menu.workbench'),
  },
  {
    path: "/home/projectDataSummary",
    component: dataSummarizing
  },
  {
    path: "/notification",
    component: notification,
    alias: i18n.t('menu.Notify')
  },
  {
    path: "/ones/authentication",
    name: "authenticationOnes",
    component: authenticationOnes,
  },
  {
    path: "/deleteProject/:deleteProcessId",
    component: deleteProject,
  },
  {
    path: "/profile",
    component: personal,
    children: [{
      path: "",
      redirect: "user_manage",
    },
    {
      path: "my_info",
      component: my_info,
    },
    {
      path: "user_group_manage",
      component: user_group_manage,
      alias: i18n.t('personal.menu.userGroup')
    },
    {
      path: "user_manage",
      component: user_manage,
      alias: i18n.t('menu.userManagement')
    },
    {
      path: "project_manage",
      component: project_manage,
      alias: i18n.t('menu.projectManagement')
    },
    {
      path: "open_api",
      component: open_api,
      alias: 'open API'
    },
    {
      path: "third_party",
      component: third_party,
      alias: i18n.t('personal.menu.thirdParty')
    }, {
      path: "Large_language",
      component: Large_language,
      alias: i18n.t('menu.Largelanguage')
    },
    {
      path: "other_settings",
      component: other_settings,
      alias: i18n.t('personal.menu.OtherSettings')
    },
    {
      path: "third_party/setting",
      component: third_party_setting,
    },
    {
      name: "third_party_detail",
      path: "third_party/detail/:applicationId",
      component: third_party_detail,
    },
    {
      name: "third_party_config",
      path: "third_party/config/:name",
      component: third_party_detail,
    },
    {
      path: "reset_email",
      component: reset_email,
    },
    {
      path: "reset_password",
      component: reset_password,
    },
    {
      path: 'certificate_management',
      alias: i18n.t('menu.certificateManagement'),
      component: () => import('@/views/certificateManagement/index.vue')
    }
    ],
  }, {
    path: '*', //匹配未定义的路由
    redirect: '/home' //重定向
  }
];
if (localStorage.getItem("Admin-Token")) {
  setToken(localStorage.getItem("Admin-Token"));
}
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  /*路由跳转时默认在页面顶部 */
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  },
});
router.beforeEach((to, from, next) => {

  let views_four = ['myMind', 'tree', 'model', 'lineword']
  let word_or_map = localStorage.getItem('default_word_or_map') ? localStorage.getItem('default_word_or_map') : 'myMind'
  if (to.name != word_or_map && (views_four.indexOf(from.name) == -1) && views_four.indexOf(to.name) > -1) {
    const query = { ...to.query }; // 克隆查询参数  
    const params = { ...to.params }; // 克隆查询参数  
    next({ name: word_or_map, query, params }); // 重定向
  }


  // let word_or_map = localStorage.getItem('default_word_or_map') ? localStorage.getItem('default_word_or_map') : 'myMind'
  // if (to.name == 'myMind' && from.name != 'lineword' && word_or_map == 'lineword') {
  //   const query = { ...to.query }; // 克隆查询参数  
  //   const params = { ...to.params }; // 克隆查询参数  
  //   console.log(params, query);
  //   next({ name: 'lineword', query, params }); // 重定向
  // }

  // 网页名称部分
  const routeTitleMap = {
    'node_detail': (route) => i18n.t('menu.node') + ' ' + route.params.nodeKey,
    'myMind': (route) => i18n.t('menu.file') + ' ' + route.params.file_key,
    'tree': (route) => i18n.t('menu.tree') + ' ' + route.params.file_key,
    'model': (route) => i18n.t('menu.model') + ' ' + route.params.file_key,
    'lineword': (route) => i18n.t('menu.wordview') + ' ' + route.params.file_key,
    'knowledge': (route) => i18n.t('certificate.KnowledgeBase') + (route.query.title ? ' ' + route.query.title : ''),
  };

  const lastMatchedRouteRecord = to.matched[to.matched.length - 1];
  if (lastMatchedRouteRecord && lastMatchedRouteRecord.alias && lastMatchedRouteRecord.alias[0]) {
    // 如果存在别名且别名不为空，使用别名设置标题 
    document.title = lastMatchedRouteRecord.alias[0];
  } else if (routeTitleMap[lastMatchedRouteRecord.name]) {
    // 如果路由名称在map中存在
    document.title = routeTitleMap[lastMatchedRouteRecord.name](to);
  } else {
    document.title = "MappingSpace";
  }

  // 无需token的页面
  const protectedRoutes = [
    "login_password",
    "login_email",
    "register",
    "thirdparty",
    "forget_psd",
    "acceptInvitation",
    "pubKnowledge",
    "pubMindmap",
    "pubWordview",
    "m_pubMindmap",
    "mm_pubMindmap",
    "m_pubWordview",
    "phoneloginp",
    "phonelogine",
    "phoneloginr",
    "phoneforget_psd",
    "phonethirdparty",
    "phone_acceptInvitation",
    'qrPoster'
  ];

  // 无需token但是有token就不能打开的页面
  const TokenRestrictedRoutes = [
    "login_password",
    "login_email",
  ]

  // MANAGER才能打开的页面
  const MANAGER_Routes = [
    "/home/bin",
    "/home/project_details",
    "/home/setting/user",
    "/home/setting/file_type/work_flow",
    "/home/setting/file_type/template_set",
    "/home/setting/file_type/field_lab",
    "/home/permission",
    "/home/change_request"
  ]

  // SYSTEM_MANAGER才能打开的页面
  const SYSTEM_MANAGER_Routes = [
    "/profile/user_manage",
    "/profile/other_settings",
    "/profile/user_group_manage"
  ]

  if (protectedRoutes.indexOf(to.name) > -1) {
    if (TokenRestrictedRoutes.indexOf(to.name) > -1) {
      let token = getToken();
      if (token === "null" || token === "" || typeof token === "undefined") {
        next();
      } else {
        next({
          path: "/home",
        });
      }
    }
    next();
  } else {
    let token = getToken();
    if (token === "null" || token === "" || typeof token === "undefined") {
      // 没有token就返回登录页
      if (to.path.indexOf("/loginDingTalk") == -1 && to.path.indexOf("/loginWeChat") == -1) {
        router.replace("/login/");
        sessionStorage.setItem("backURL", to.path);
      } else {
        next();
      }
    } else {
      // 没有职位就返回home页
      if (sessionStorage.getItem("jobAuthorities") && sessionStorage.getItem("jobAuthorities").indexOf('KNOWLEDGE_ACCESS') === -1 && to.name === "knowledge") {
        next({
          path: "/home",
        });
      }
      // 不是 MANAGER 不能访问下面的路由
      if (!sessionStorage.getItem("projectRole") && to.params.projectId) {
        get_project_members(to.params.projectId).then((res) => {
          const current_user_id = JSON.parse(localStorage.getItem("store")).user
            .userAccountId;
          res.forEach((user) => {
            if (user.accountId === current_user_id) {
              const role = user.projectRole
              if (
                role !== "MANAGER" &&
                MANAGER_Routes.some(path => to.path.includes(path))
              ) {
                next({
                  path: "/home",
                });
              } else if (
                // 不是 SYSTEM_MANAGER 不能访问下面的路由
                localStorage.getItem("store") &&
                JSON.parse(localStorage.getItem("store")).user.roles &&
                JSON.parse(localStorage.getItem("store")).user.roles[0] &&
                JSON.parse(localStorage.getItem("store")).user.roles[0].name !==
                "SYSTEM_MANAGER" &&
                SYSTEM_MANAGER_Routes.some(path => to.path.includes(path))
              ) {
                next({
                  path: "/profile/project_manage",
                });
              } else {
                next();
              }
            }
          });
        });
      } else {
        if (
          sessionStorage.getItem("projectRole") !== "MANAGER" &&
          MANAGER_Routes.some(path => to.path.includes(path))
        ) {
          next({
            path: "/home",
          });
        } else if (
          // 不是 SYSTEM_MANAGER 不能访问下面的路由
          localStorage.getItem("store") &&
          JSON.parse(localStorage.getItem("store")).user.roles &&
          JSON.parse(localStorage.getItem("store")).user.roles[0] &&
          JSON.parse(localStorage.getItem("store")).user.roles[0].name !==
          "SYSTEM_MANAGER" &&
          SYSTEM_MANAGER_Routes.some(path => to.path.includes(path))
        ) {
          next({
            path: "/profile/project_manage",
          });
        } else {
          next();
        }
      }
    }
  }
});

export default router;
