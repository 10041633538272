<template>
  <div id="app" @dragover.prevent @drop.prevent>
    <router-view v-if="isRouterAlive"></router-view>
    <waterMask v-if="token && watermask_switch"></waterMask>
  </div>
</template>

<script>
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import { mapGetters, mapMutations } from "vuex";
import {
  unSeen,
  get_notifications_list,
} from "@/network/notification/index.js";
import { getToken } from "@/utils/auth";
import waterMask from "@/components/waterMask";
import { getWatermark } from "@/network/watermask";
import { sessionSave, sessionRead, sessionRemove } from "@/utils/session";
import { get_status_list } from "@/network/workflow/index.js";
import { getPhoneInfo } from "@/network/login";
export default {
  name: "App",
  components: {
    waterMask,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  computed: {
    ...mapGetters(["token", "userAccountId", "watermask_switch"]),
    p_id() {
      return this.get_pid();
    },
    cookie_token() {
      return getToken();
    },
  },
  watch: {
    // 将vuex中的修改即时的同步到localstorage
    "$store.state": {
      handler: function () {
        localStorage.setItem("store", JSON.stringify(this.$store.state));
      },
      deep: true,
    },
    p_id(newv, oldv) {
      if (oldv) {
        this.$store.commit("SET_BASELINE_ID", "");
        this.$store.commit("SET_BASELINE_STATUS", "");
        this.$store.commit("SET_BASELINE_EDIT", false);
      }
      if (newv && this.token) {
        this.closeWebsocket();
        this.initWebsocket();
      }
    },
    cookie_token(val, oldVal) {
      if (!oldVal && val) {
        this.initWebsocket();
      }
      //如果原先由，现在没有了，说明退出登录，断开websocket
      if (oldVal && !val) {
        this.closeWebsocket();
      }
    },
  },
  created() {
    if (localStorage.getItem("store")) {
      sessionStorage.setItem(
        "role",
        JSON.stringify(
          JSON.parse(localStorage.getItem("store"))
            ? JSON.parse(localStorage.getItem("store")).user.roles
            : []
        )
      );
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("storage", (e) => {
      this.$store.commit(
        "REFRESE_STATE",
        JSON.parse(localStorage.getItem("store"))
          ? JSON.parse(localStorage.getItem("store")).user
          : {}
      );
    });
    // 定义路径映射对象，键为手机端路径，值为PC端路径
    const routeMap = {
      "/login/login_password": "/phoneloginp",
      "/login/login_email": "/phonelogine",
      "/login/register": "/phoneloginr",
      "/login/forget_psd": "/phoneforget_psd",
      "/login/thirdparty": "/phonethirdparty",
    };

    this.$router.afterEach(() => {
      if (this._isMobile()) {
        document.addEventListener("DOMContentLoaded", function () {
          document.body.style.overflowY = "auto";
        });
        // 如果是手机端
        this.isPhoneRoutes(routeMap);
      } else {
        // 如果是pc端
        this.isNoPhoneRoutes(routeMap);
      }
    });
  },
  mounted() {
    if (this.cookie_token) {
      getWatermark().then((res) => {
        this.SET_WATERMASK({
          waterMaskSwitch: res.switchFlag ? true : false,
          waterMaskValue: res.content ? res.content : "",
        });
      });
      this.getPhoneInfos();
    }
  },
  methods: {
    ...mapMutations(["SET_WATERMASK"]),
    getPhoneInfos() {
      getPhoneInfo().then((res) => {
        if (!res.contactPhone) {
          // this.$router.push("/choseLargelanguage");
        }
      });
    },
    async get_status_list(pid, status, ruleMinutes, nodeKeyList, nowIdnex) {
      this.status_index_List.push({ [status]: nowIdnex });
      this.nodeKey_index_List[nowIdnex] = nodeKeyList;
      if (this.pidSet.has(pid)) {
        return;
      } else {
        this.pidSet.add(pid);
        const res = await get_status_list(pid);
        this.statusPList = {
          ...this.statusPList,
          [pid]: res,
        };
        res.forEach((item) => {
          for (let i of this.status_index_List) {
            if (
              typeof i[item.statusId] === "number" &&
              !isNaN(i[item.statusId]) &&
              !this.card_list[i[item.statusId]].content
            ) {
              this.card_list[i[item.statusId]].content =
                "（" +
                item.name +
                "-" +
                this.this.$t("notice.over") +
                " " +
                parseFloat(ruleMinutes / 60 / 24).toFixed(2) +
                " " +
                this.this.$t("notice.days") +
                "）" +
                this.nodeKey_index_List[i[item.statusId]].join(",\n");
            }
          }
        });
      }
    },
    getStatusName(status, pid, ruleMinutes, nodeKeyList, nowIdnex) {
      if (!this.statusPList[pid]) {
        this.get_status_list(pid, status, ruleMinutes, nodeKeyList, nowIdnex);
      } else {
        let name = "";
        this.statusPList[pid].forEach((item) => {
          if (item.statusId == status) {
            name = item.name;
            return;
          }
        });
        return (
          "（" +
          name +
          "-" +
          this.this.$t("notice.over") +
          " " +
          parseFloat(ruleMinutes / 60 / 24).toFixed(2) +
          " " +
          this.this.$t("notice.days") +
          "）" +
          nodeKeyList.join(",\n")
        );
      }
    },
    getProjectName(pid) {
      const project = this.t_joined_projects.find((item) => {
        return pid === item.projectId;
      });
      return project.projectName;
    },
    async initWebsocket() {
      let socket = new SockJS(
        `${this.wsurl}${
          this.wsurl ? "" : "/prod-api"
        }/file-manage-service/sendServer?accountId=${this.userAccountId}`
      );
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {
          token: this.token,
          projectId: this.get_pid(),
          scene: "NOTIFICATION_CENTER",
        }, //传递token
        (frame) => {
          // 测试topic
          this.stompClient.subscribe(
            `/user/queue/NOTIFICATION_CENTER`,
            (res) => {
              get_notifications_list({
                pageNumber: 0,
                pageSize: 1,
              }).then((rrr) => {
                let item = rrr.content[0];
                let cont = "";
                switch (item.action) {
                  case "assign":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      item.businessData.topic;
                    break;
                  case "batchAssign":
                    cont = item.businessData.nodeKeyList
                      .map((item1, index) =>
                        index === item.businessData.nodeKeyList.length - 1
                          ? item1
                          : item1 + "，"
                      )
                      .join("");
                    break;
                  case "knowledgeQuickShare":
                    cont = item.businessData.docTitle;
                    break;
                  case "followDocChanged":
                    cont = item.businessData.docTitle;
                    break;
                  case "followDocCommented":
                    cont =
                      item.businessData.docTitle +
                      ": " +
                      item.businessData.plainText;
                    break;
                  case "atInDocument":
                    cont = item.businessData.docTitle;
                    break;
                  case "docCommentAt":
                    cont =
                      item.businessData.docTitle +
                      ":" +
                      item.businessData.plainText;
                    break;
                  case "docWordCommentAt":
                    cont =
                      item.businessData.docTitle +
                      ":" +
                      item.businessData.plainText;
                    break;
                  case "commentAt":
                    cont = "（" + item.businessData.nodeKey + "）";
                    JSON.parse(item.businessData.detail).forEach((i) => {
                      if (i.type === "at") {
                        cont = cont + " @" + i.data.nickname + " ";
                      } else if (i.type === "text") {
                        cont = cont + i.data;
                      } else {
                        cont = cont + "[" + this.this.$t("notice.Images") + "]";
                      }
                    });
                    break;
                  case "review":
                    cont = item.businessData.reviewProcessTitle;
                    break;
                  case "endReview":
                    cont = item.businessData.reviewProcessTitle;
                    break;
                  case "inviteDeleteProject":
                    cont = item.businessData.projectDto.projectName;
                    break;
                  case "endDeleteProject":
                    cont = item.businessData.projectDto.projectName;
                    break;
                  case "ganttStartNotifyAssignee":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      item.businessData.topic;
                    break;
                  case "ganttEndNotifyReliedAssignee":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      item.businessData.topic;
                    break;
                  case "ganttStartNotifyCollector":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      item.businessData.topic;
                    break;
                  case "ganttEndNotifyReliedCollector":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      item.businessData.topic;
                    break;
                  case "versionReleased":
                    cont =
                      "(" +
                      this.getProjectName(item.projectId) +
                      ") " +
                      item.businessData.versionName;
                    break;
                  case "changeRequestReviewUnfinished":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      item.businessData.changeRequestName;
                    break;
                  case "changeRequestRelated":
                    cont =
                      "（" +
                      (item.businessData.relatedNodeKeyList.length > 3
                        ? item.businessData.relatedNodeKeyList.slice(0, 3) +
                          "..."
                        : item.businessData.relatedNodeKeyList) +
                      "）" +
                      item.businessData.nodeKey;
                    break;
                  case "statusTimeOut":
                    cont = this.getStatusName(
                      item.businessData.status,
                      item.projectId,
                      item.businessData.ruleMinutes,
                      item.businessData.nodeKeyList,
                      0
                    );
                    break;
                  case "projectConfigAbnormalChangesNotify":
                    cont =
                      item.businessData.invalidCount +
                      this.$t(
                        "notice.projectConfigAbnormalChangesNotifyMessage"
                      );
                    break;
                  case "changeRequest":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      item.businessData.changeRequestName;
                    break;
                  case "nodeStatusReopenNotify":
                    cont =
                      "（" +
                      item.businessData.nodeKey +
                      "）" +
                      this.$t("notice.nodeStatusReopenNotify");
                    break;
                  case "taraRiskAnalysis":
                    cont = "（" + item.businessData.nodeKey + "）";
                    break;
                  case "baselineChange":
                    cont =
                      this.$t("notice.baselineChange") +
                      "（" +
                      item.businessData.changeType +
                      "）";
                    break;
                }
                console.log(item);

                // const actionHandlers = {
                //   customSingleUserFieldChange: () => {
                //     return `${item.businessData.fieldName} ${this.$t(
                //       "notice.FieldAssignmentReminder"
                //     )}：${item.businessData.nodeKey}`;
                //   },
                //   customMultiUserFieldChange: () => {
                //     return `${item.businessData.fieldName} ${this.$t(
                //       "notice.FieldAssignmentReminder"
                //     )}：${item.businessData.nodeKey}`;
                //   },
                //   applyAccessOnDoc: () => {
                //     return `${messageType[action]}：${item.businessData.docTitle}`;
                //   },
                //   followDocCommented: () => {
                //     return item.businessData.commentType === "LINE"
                //       ? `${this.$t("notice.wordComments")}：${getContent(item)}`
                //       : `${this.$t("notice.newPageComments")}：${getContent(
                //           item
                //         )}`;
                //   },
                //   batchAssign: () => {
                //     return `${this.$t("notice.batchAssign")}：${getContent(
                //       item
                //     )}`;
                //   },
                //   docInternetPermission: () => {
                //     return `${messageType[action]} ${
                //       item.businessData.internetEdit
                //         ? this.$t("notice.editable")
                //         : this.$t("notice.readable")
                //     }：(${getProjectName(item.projectId)}) ${
                //       item.businessData.docTitle
                //     }`;
                //   },
                //   dfmeaOptimization: () => {
                //     return `${messageType[action]}：(${getProjectName(
                //       item.projectId
                //     )}) ${item.businessData.optimizationTitle}`;
                //   },
                //   dfmeaOptimizationEnd: () => {
                //     return `${messageType[action]}：(${getProjectName(
                //       item.projectId
                //     )}) ${item.businessData.optimizationTitle}`;
                //   },
                //   versionReleased: () => {
                //     return `${messageType[action]}：(${getProjectName(
                //       item.projectId
                //     )}) ${item.businessData.versionName}`;
                //   },
                //   changeRequestReviewUnfinished: () => {
                //     return `${messageType[action]}：${getContent(item)}`;
                //   },
                //   changeRequest: () => {
                //     return `${messageType[action]}：${getContent(item)}`;
                //   },
                //   statusTimeOut: () => {
                //     return `${messageType[action]}：${getContent(item)}`;
                //   },
                //   taraRiskAnalysis: () => {
                //     return `${this.$t("tara.notice")} ${
                //       item.businessData.nodeKey
                //     }`;
                //   },
                //   shareFileViewRequest: () => {
                //     return `${this.$t("share.notice")} ${
                //       item.businessData.fileKey
                //     }`;
                //   },
                //   haraSafetyAnalysis: () => {
                //     return `${this.$t("hara.notice")} ${
                //       item.businessData.nodeKey
                //     }`;
                //   },
                //   changeRequestRelated: () => {
                //     let content = `${messageType[action]}：`;
                //     if (item.businessData.relatedNodeKeyList.length > 3) {
                //       content += `<el-tooltip effect="dark" :content="${item.businessData.relatedNodeKeyList.join(
                //         ", "
                //       )}" placement="bottom"><span>${getContent(
                //         item
                //       )}</span></el-tooltip>`;
                //     } else {
                //       content += getContent(item);
                //     }
                //     return content;
                //   },
                // };

                // const action = item.action;
                // const message = actionHandlers[action]
                //   ? actionHandlers[action]()
                //   : `${messageType[action]}：${getContent(item)}`;
                let messageType = {
                  assign: this.$t("notice.assign"),
                  knowledgeQuickShare: this.$t("notice.knowledgeQuickShare"),
                  atInDocument: this.$t("notice.atInDocument"),
                  commentAt: this.$t("notice.commentAt"),
                  review: this.$t("notice.review"),
                  endReview: this.$t("notice.endReview"),
                  inviteDeleteProject: this.$t("notice.inviteDeleteProject"),
                  endDeleteProject: this.$t("notice.endDeleteProject"),
                  ganttStartNotifyAssignee: this.$t(
                    "notice.ganttStartNotifyAssignee"
                  ),
                  ganttStartNotifyCollector: this.$t(
                    "notice.ganttStartNotifyCollector"
                  ),
                  ganttEndNotifyReliedAssignee: this.$t(
                    "notice.ganttEndNotifyReliedAssignee"
                  ),
                  ganttEndNotifyReliedCollector: this.$t(
                    "notice.ganttEndNotifyReliedCollector"
                  ),
                  customSingleUserFieldChange: this.$t(
                    "notice.customSingleUserFieldChange"
                  ),
                  customMultiUserFieldChange: this.$t(
                    "notice.customMultiUserFieldChange"
                  ),
                  applyAccessOnDoc: this.$t("notice.applyAccessOnDoc"),
                  reviewEndInFourHours: this.$t("notice.reviewEndInFourHours"),
                  docWordCommentAt: this.$t("notice.docWordCommentAt"),
                  docCommentAt: this.$t("notice.docCommentAt"),
                  followDocChanged: this.$t("notice.followDocChanged"),
                  followDocCommented: this.$t("notice.followDocCommented"),
                  testPlanEndInOneDay: this.$t("notice.testPlanEndInOneDay"),
                  docInternetPermission: this.$t(
                    "notice.docInternetPermission"
                  ),
                  dfmeaOptimization: this.$t("notice.dfmeaOptimization"),
                  dfmeaOptimizationEnd: this.$t("notice.dfmeaOptimizationEnd"),
                  changeRequestReviewUnfinished: this.$t("changeRequest.title"),
                  changeRequest: this.$t("notice.changeRequest"),
                  changeRequestRelated: this.$t("notice.changeRequestRelated"),
                  statusTimeOut: this.$t("notice.statusTimeOut"),
                  versionReleased: this.$t("notice.version"),
                  taraRiskAnalysis: this.$t("tara.notice"),
                  projectConfigAbnormalChangesNotify: this.$t(
                    "notice.projectConfigAbnormalChangesNotify"
                  ),
                };

                this.$notify.info({
                  title: messageType[item.action],
                  message:
                    cont.length > 500 ? cont.slice(0, 500) + "..." : cont,
                  // iconClass:'el-icon-info',
                  iconClass: "notify_zdh_iconClass",
                  customClass: "notify_zdh_customClass",
                  onClick: () => {
                    this.$router.push("/notification");
                  },
                });
              });
              unSeen().then((res) => {
                if (res.unSeenNumber) {
                  this.$store.commit("SET_NOTICE", true);
                } else {
                  this.$store.commit("SET_NOTICE", false);
                }
                this.$store.commit("SET_NOTICE_NUM", res.unSeenNumber);
              });
            }
          );
        },
        (err) => {
          console.log("错误：" + err);
        }
      );
      this.stompClient.heartbeat.outgoing = 20000; //若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
      this.stompClient.heartbeat.incoming = 0; //客户端不从服务端接收心跳包
    },
    closeWebsocket() {
      if (this.stompClient) {
        try {
          this.stompClient.disconnect(() => {});
        } catch (e) {
          console.log(e);
        }
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      return !!navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
    // 手机端
    isPhoneRoutes(routeMap) {
      // 手机端路由跳转逻辑
      if (window.location.pathname.indexOf("phone") !== -1) {
        // 如果当前路由已经是手机端路由，不执行跳转
        return;
      }
      // 如果不是手机端路由，根据当前路由跳转至相应手机端路由
      const phoneRoute = routeMap[window.location.pathname];
      if (phoneRoute) {
        this._redirectWithParams({ name: phoneRoute });
      } else if (
        window.location.pathname.indexOf("/login/acceptInvitation") !== -1
      ) {
        this._redirectWithParams({ name: "/phone_acceptInvitation" });
      }
    },
    // PC端
    isNoPhoneRoutes(routeMap) {
      // pc端路由跳转逻辑
      if (window.location.pathname.indexOf("phone") === -1) {
        // 如果当前路由已经是pc端路由，不执行跳转
        return;
      }
      // 如果不是移动端路由，根据当前路由跳转至相应移动端路由
      const pcRoute = Object.keys(routeMap).find(
        (key) => routeMap[key] === window.location.pathname
      );
      if (pcRoute) {
        this._redirectWithParams({ name: pcRoute });
      } else if (
        window.location.pathname.indexOf("/phone_acceptInvitation") !== -1
      ) {
        this._redirectWithParams({ name: "/login/acceptInvitation" });
      }
    },
    // 路径判断
    _redirectWithParams(route) {
      if (window.location.pathname.indexOf("/login/acceptInvitation/") !== -1) {
        const url = window.location.pathname.replace(
          "/login/acceptInvitation/",
          "/phone_acceptInvitation/"
        );
        this.$router.push({ path: url });
      } else if (
        window.location.pathname.indexOf("/phone_acceptInvitation/") !== -1
      ) {
        const url = window.location.pathname.replace(
          "/phone_acceptInvitation/",
          "/login/acceptInvitation/"
        );
        this.$router.push({ path: url });
      } else {
        if (window.location.search) {
          // 如果当前路由有参数，则传递参数进行跳转
          this.$router.push({ path: route.name + window.location.search });
        } else {
          // 如果当前路由没有参数，则直接跳转
          this.$router.push({ path: route.name });
        }
      }
    },
  },
};
</script>
<style>
#app {
  font-family: "思源黑体" !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}
html {
  overflow: -moz-hidden-unscrollable;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
a {
  text-decoration: none;
}
.notify_zdh_customClass {
  cursor: pointer;
}
.notify_zdh_iconClass {
  color: #67c23a !important;
}
</style>
